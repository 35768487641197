<template>
  <b-sidebar id="add-new-categoria-patrocinadores-sidebar" :visible="isEditSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-new-sidebar-active', val)">
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Adicionar categoria de patrocinador</h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
      </div>
      <validation-observer
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="submit()"
        >
        <validation-provider
          v-slot="{ errors }"
          name="title"
          rules="required"
        >
          <b-form-group
            label="Titulo"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="form.title"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="size"
          rules="required"
        >
          <b-form-group
            label="Tamanho"
            label-for="size"
          >
            <b-form-select id="size" v-model="form.size" :options="options"  :state="errors.length > 0 ? false : null"></b-form-select>
        
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="{ errors }"
          name="type"
          rules="required"
        >
          <b-form-group
            label="Opções"
            label-for="type"
          >
            <b-form-select id="type" v-model="form.opcao" :options="options_type"  :state="errors.length > 0 ? false : null"></b-form-select>
        
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            Atualizar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip,} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend  } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        order: null,
        title: null,
        opcao: null, 
        size: null
      },
      options: [
        { value: 30, text: 'Extra Pequeno' },
        { value: 50, text: 'Pequeno' },
        { value: 100, text: 'Médio' },
        { value: 150, text: 'Grande' },
        { value: 200, text: 'Extra Grande' },
        { value: 250, text: 'Muito Grande' },
      ],
      options_type: [
        {value: 'exibir', text: 'Exibir'},
        {value: 'nao_exibir', text: 'Não exibir'},
      ]
    }
  },
  methods: {
    resetForm() {
      this.form = {
        id: null,
        title: null
      }
      this.$emit('close')
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          try {
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('title', this.form.title)
            formData.append('size', this.form.size)
            formData.append('opcao', this.form.opcao)
            axios.post(`${process.env.VUE_APP_API}/patrocinadores/categorias/update`, formData)
              .then(response => {
                console.log(response.data)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Sucesso ao atualizar!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
                this.resetForm()
                this.isEditSidebarActive = false
              })
              .catch(error => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: error.message,
                    variant: 'danger',
                  }
                })
              })

            
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
  },
  mounted() {
    console.log(this.data)
    this.form.id = this.data.id
    this.form.title = this.data.title
    this.form.opcao = this.data.opcao
    this.form.size = this.data.size
  }
}

</script>

<style>

</style>